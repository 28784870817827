import React from 'react';
import ruaPlans from '../images/ruaPlans2.png';
import firebase from "firebase/compat";
import { NavBar } from "./HeroPage";
import anvilMini from "../images/update/anvilMini.png";

//todo-cache update process with new copy (TBD)
export class Process extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const P1 =
      "We share with you our information pack showing all of our plans and pricing. Upon seeing our products you are welcome to come view our show home in Greenhithe Auckland or book a virtual meeting. In this meeting we will discuss any queries you may have and our complete build process. To book a meeting email alex@dreamwoodhomes.co.nz or call 022 191 0820.\n" +
      "\n";
    const P2 = `Choose from one of our custom designed 8 plans.`;
    const P3 = `Meet with us to go through all the intricacies of your site and design details for your tiny home.`;
    const P4 = `A contract will be sent to you for your approval/signing. This will have a full scope of works and inclusions. 
`;
    const P5 = `Upon signing of the contract our typical build time is 10 weeks.`;

    const P6 = `Once your home has been constructed, we deliver the units via our HIAB contractor anywhere within New Zealand.
    We are based in Auckland, however will deliver from the Cape to Bluff.
    `;
    const P7 = `Time to enjoy your new Tiny Home!`;
    // const P8 = `
    //    After the home is delivered and connected to all services, now the house is yours and ready to enjoy!
    //      \nWe will walk through the site to ensure you are happy with the product and answer any questions. `;
    console.log("firebase:", firebase.apps.length);
    return (
      <div>
        <NavBar />
        <div
          className="py-5 mainImageBackground text-white"
          style={{ height: "88vh" }}
        >
          <div
            className="mainImageBackground py-6 text-white position-relative px-4"
            id="timeline"
          >
            <h1 className="mb-5 text-center">
              <span
                className="mx-auto d-inline-block p-4 mb-0 shadow rounded-3"
                style={{ color: "#fff", backgroundColor: "#505050" }}
              >
                Our Process
              </span>
            </h1>
            <div className="container c-center">
              <div className="row text-white-50 s-around">
                <div className="col border-left-dotted">
                  <div
                    className="p-2 d-inline-block timelineBox rounded text-start"
                    style={{ marginBottom: 20 }}
                  >
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#1 Enquire <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        search
                    </i></b>
                    <br />
                    {P1}
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col-8 col-md-6 pb-3 border-left-dotted">
                  <div className="rounded-3 timelineBox py-3 px-4">
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#2 Choose Design <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        shopping_cart
                    </i> </b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >

                    </div>
                    {P2}{" "}
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col border-left-dotted">
                  <div
                    className="p-2 d-inline-block timelineBox rounded text-start"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="rounded-3 timelineBox py-3 px-4">
                      <b style={{display:'flex',justifyContent:'inline-flex'}}>#3 Meet Us  <i
                          style={{ fontSize: 48 , marginLeft:20}}
                          className="material-icons large"
                      >
                          schedule
                      </i></b>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                        }}
                      >

                      </div>
                      {P3}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col-8 col-md-6 pb-3 border-left-dotted">
                  <div className="rounded-3 timelineBox py-3 px-4">
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#4 Contract  <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        receipt_long
                    </i></b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >

                    </div>
                    {P4}
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col-8 col-md-6 pb-3 border-left-dotted">
                  <div className="rounded-3 timelineBox py-3 px-4">
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#5 Start Building! <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        construction
                    </i></b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >

                    </div>
                    {P5}{" "}
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col border-left-dotted">
                  <div
                    className="p-2 d-inline-block timelineBox rounded text-start"
                    style={{ marginBottom: 20 }}
                  >
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#6 Delivery  <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        local_shipping
                    </i></b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >

                    </div>
                    {P6}
                  </div>
                </div>
              </div>
              <div className="row text-white-50 s-around">
                <div className="col-8 col-md-6 pb-3 border-left-dotted">
                  <div className="rounded-3 timelineBox py-3 px-4">
                    <b style={{display:'flex',justifyContent:'inline-flex'}}>#7 Enjoy! <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                    >
                        emoji_emotions
                    </i></b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 60,
                        paddingRight: 60,
                      }}
                    >

                    </div>
                    {P7}{" "}
                  </div>
                </div>
              </div>
              {/* <div className="row text-white-50 s-around">
                <div className="col-8 col-md-6 pb-3 border-left-dotted">
                  <div className="rounded-3 timelineBox py-3 px-4">
                    <b>#7 Handover</b>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 60,
                        paddingRight: 60,
                      }}
                    >
                      <i
                        style={{ fontSize: 48 , marginLeft:20}}
                        className="material-icons large"
                      >
                        key
                      </i>
                    </div>
                    {P8}{" "}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
