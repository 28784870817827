import React from 'react';
import person from '../images/person.jpeg';
import mail from '../images/mail.png';
import tahi from '../images/newRoof.png';
import rua from '../images/reroof.jpeg';
import toru from '../images/roofmaintenance.png';
import dreamwoodMain from '../images/dwmain2.png';
import home_icon from '../images/realtor-icon-4-dark.png';
import logo from '../images/DW_logos_reversed.png';
import firebase from "firebase/compat";
import {NavBar} from "./HeroPage";
import i1 from '../images/marketing/12-32-13.jpg'
import i2 from '../images/marketing/12-32-13 2.jpg'
import i3 from '../images/marketing/12-32-14.jpg'
import i5 from '../images/marketing/12-32-14 2.jpg'
import i6 from '../images/marketing/12-32-14 3.jpg'
import i7 from '../images/marketing/12-32-15.jpg'
import i8 from '../images/marketing/12-32-15 2.jpg'
import i9 from '../images/marketing/12-32-16.jpg'
import i10 from '../images/marketing/12-32-16 2.jpg'
import i11 from '../images/marketing/12-32-23.jpg'
import i12 from '../images/marketing/12-32-24.jpg'
import i13 from '../images/marketing/12-32-24 2.jpg'
import i14 from '../images/marketing/12-32-24 3.jpg'
import i15 from '../images/marketing/12-32-25.jpg'
import i16 from '../images/marketing/12-32-25 2.jpg'
import i17 from '../images/marketing/12-32-25 3.jpg'
import i18 from '../images/marketing/12-32-26.jpg'
import i19 from '../images/marketing/12-32-26 2.jpg'
import i20 from '../images/marketing/12-32-27.jpg'
import i21 from '../images/marketing/12-32-27 2.jpg'
import i22 from '../images/marketing/12-32-28.jpg'
import i23 from '../images/marketing/12-32-28 2.jpg'
import i24 from '../images/marketing/12-32-28 3.jpg'
import i25 from '../images/marketing/12-32-29.jpg'
import i26 from '../images/marketing/12-33-22.jpg'
import i28 from '../images/marketing/12-33-22 3.jpg'
import i29 from '../images/marketing/12-33-23.jpg'
import i31 from '../images/marketing/12a5e0e0-c3a7-4c8d-9212-0b02f8a69577.JPG';
import i32 from '../images/marketing/12b05c7d-5602-47ed-8f89-47b92722b11a.JPG';
import i33 from '../images/marketing/25d01348-4f5c-4b4b-903a-843d8cdf8d1c.JPG';

import i36 from '../images/marketing/239bd78b-b6d3-4d67-92fb-48fe1b2acf6c.JPG';
import i37 from '../images/marketing/275ff47e-d2f9-49b7-86b1-ef57c4bec3ae.JPG';
import i39 from '../images/marketing/3981d96d-9c10-4cd8-a0d5-87d9dbd3ff29.JPG';
import i41 from '../images/marketing/4516e2ee-f63b-4867-896b-4424cfde36eb 2.JPG';
import i44 from '../images/marketing/886646ff-d2fa-4be8-b76f-eb2f9c335b6d.JPG';
import i45 from '../images/marketing/989887e1-2fc8-43e3-bbe0-6e02da8d5d1a.JPG';
import i46 from '../images/marketing/a7031373-d517-4f0a-96d6-bf364b92adfa.JPG';
import i49 from '../images/marketing/c2bdb42b-62fd-43dd-912b-19a0a514170d.JPG';
import i51 from '../images/marketing/e3bd2470-4bb0-4d18-ac8c-3cd2d4e591f6.JPG';
import i52 from '../images/marketing/e5acf67d-3f9b-4688-a136-12b69e7263c0.JPG';
import i55 from '../images/marketing/0b8c54d3-a43f-47e0-889c-c8ed6076fa9e.JPG';
import i57 from '../images/marketing/2d97d48e-68ae-4d98-bbb9-b8d13b54cc64 2.JPG';
import i58 from '../images/marketing/02e71d3e-3124-4f91-a161-978f5f30ccef.JPG';
import i59 from '../images/marketing/3d31ef8d-5c45-40ac-b5b6-e9b09aa84d24.JPG';
import i62 from '../images/marketing/6f32c65c-e6c9-46af-b323-2f38aec085bd.JPG';
import i64 from '../images/marketing/8f6b7ac6-2629-4527-8f35-b8e06dca8958.JPG';
import i65 from '../images/marketing/8fcbc7db-082c-4503-8e47-2bce2c143b62.JPG';



//todo-cache order images correctly. remove any images with construction debris.
export class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            activePage:'HOME',
            imgSelected:null,
        }
    }
    toggleActivePage(page){
        return this.setState({activePage:page})
    }
    render(){
        const images=[i65,i44,i36,i37,i39,i36,i59,i55,i62,i29,i64,i45,i46,i49,i51,i52,i57,i58,
            i6
            , i7
            , i8
            , i9,
            i31,
            i32,
            i33,
        i1,
        i2,
        i3,
        i5
        , i10
        , i11
        , i12
        , i13
        , i14
        , i15
        , i16
        , i17
        , i18
        , i19
        , i20
        , i21
        , i22
        , i23
        , i24
        , i25
        ];
        return <div>
            <NavBar toggleActivePage={this.toggleActivePage.bind(this)}/>
            <div style={{position:'relative',display:'flex',justifyContent:'center'}}>{this.state.imgSelected!==null&&<div style={{borderRadius:12,position:'absolute',top:10,backgroundColor:'#2c2c2c',height:'auto',width:'auto',zIndex:9000}}>
                <div style={{position:'absolute',right:10,top:'47%'}}>
                    <i onClick={()=>{this.setState({imgSelected:this.state.imgSelected+=1})}} style={{visibility:this.state.imgSelected<images.length-1?'visible':'hidden'}} className="imageNextButton large material-icons">keyboard_arrow_right</i>
                </div>
                <div style={{position:'absolute',left:10,top:'47%'}}>
                    <i onClick={()=>{this.setState({imgSelected:this.state.imgSelected-=1})}} style={{visibility:this.state.imgSelected>0?'visible':'hidden'}} className="imageNextButton large material-icons">keyboard_arrow_left</i>
                </div>
                <div style={{position:'absolute',width:'100%',display:'flex',justifyContent:'center',top:'80%'}}>
                    <div style={{  color:'#fff',
                        backgroundColor: '#7b7b7b',borderRadius:12,fontSize:36,opacity:0.75}}>
                        {this.state.imgSelected} / {images.length}
                    </div>

                </div>
                <div style={{width:'100%',height:30}}>
                    <div onClick={()=>{this.setState({imgSelected:null})}} style={{float:'right',cursor:'pointer',paddingRight:5,paddingTop:5,color:'#fff',fontSize:20,fontWeight:900}}><i className="material-icons">close</i></div>
                </div>
                <div style={{padding:30,paddingTop:0, display:'flex',justifyContent:'center',width:'98vw',height:'90vh'}}>
                    <img style={{objectFit:'scale-down',width:'100%'}}  src={images[this.state.imgSelected]}/></div>
            </div>}</div>
            <div className={`mainImageBackground text-white ${this.state.imgSelected&&'selectedPopupOpaque'}`} style={{height:'87.9vh'}}>
                <div>
                    <div style={{height:'80vh',overflowY:'auto',display:'flex',justifyContent:'center'}}>
                        <div style={{display:'inline-flex',flexWrap:'wrap',placeContent:'space-around',alignContent:'space-between'}}>
                                {images.map((image,ix)=>{
                                    return(<div  style={{position:'relative'}}>
                                        <div className="myDIV2" onClick={()=>{
                                            firebase.analytics().logEvent(`view_image_gallery`);

                                            this.setState({imgSelected:ix})}}>
                                            <img key={`${image}`}  style={{marginTop:20,marginBottom:20,objectFit:'cover',padding:5,maxWidth:'45vw',maxHeight:'60vh'}} src={image} className="rounded-4 hoverImage"/></div>
                                    </div>);
                                    }
                                )}
                        </div>

                    </div>
                </div>

            </div>
        </div>

    }
}