import React from "react";
import person from "../images/person.jpeg";
import mail from "../images/mail.png";
import tahi from "../images/newRoof.png";
import Anvil from "../images/update/Anvil.jpg";
import Claris from "../images/update/Claris.jpg";
import Medlands from "../images/update/Medlands.jpg";
import InteriorPhotoMain from "../images/update/interior1.png";
import InteriorPhotoMain2 from "../images/update/interiorDark.png";
import tahi2 from "../images/tahi.jpeg";
import rua from "../images/reroof.jpeg";
import toru from "../images/roofmaintenance.png";
import dreamwoodMain from "../images/dwmain2.png";
import dreamwoodMain2 from "../images/toru2.png";
import inside from "../images/inside.png";
import AJ from "../images/AJ.jpeg";
import CS from "../images/CS.jpeg";
import LBP from "../images/LBP-logo.jpeg";
import LTR from "../images/Life-Time-Roofing-logo.jpeg";
import RMB from "../images/RMB-logo.jpeg";
import SS from "../images/SiteSafe-logo.jpeg";
import Schneider from "../images/selt3.png";
import home_icon from "../images/realtor-icon-4-dark.png";
import logo from "../images/DW_logos_reversed.png";
import tagline from "../images/update/tagline.png";
import firebase from "firebase/compat";
import M from "materialize-css";
import {
  businessBlurb,
  contactEmail,
  contactPhone,
  contactTitle,
  mailChimpSource,
  secondary,
  secondaryContent,
  secondaryContentTitle,
  supportingBlurb,
  supportingHeading,
  titleBlurb,
  titleContent,
  trustedPartnerContent,
  RouteItems,
} from "../content";
import { PlanShowcase } from "../content/PlanShowcase";
import { WisehomeContent } from "../content/Wisehome";
import { rootStore } from "../stores/Store";

export const NavBar = (props) => (
  <nav
    className={`navbar navbar-expand-xl navbar-dark mainImageBackground border-bottom border-dark myNav`}
  >
    <div className="container">
      <a className="navbar-brand">
        <img src={logo} alt="" width="130" />
        <img style={{marginLeft:( window.innerWidth <= 900 ) && ( window.innerHeight <= 1000 )?0:20}} src={tagline} alt="" height={( window.innerWidth <= 900 ) && ( window.innerHeight <= 1000 )?50:70} />
      </a>
      <button
        className="navbar-toggler rounded-4 shadow-sm"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse px-3"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav ms-auto me-0 mb-2 mb-lg-0">
          {RouteItems.map((route) => {
            if (route.dropArray) {
              return (
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    className="dropdown nav-link"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="dropbtn"
                      onClick={() => {
                        firebase.analytics().logEvent(route.routeTag);
                        window.fbq("trackCustom", `${route.routeTag}`);

                        window.location.href = route.href;
                      }}
                    >
                      {route.name}
                    </div>
                    <div
                      className="dropdown-content"
                      style={{ zIndex: 999, position: "absolute" }}
                    >
                      {route.dropArray.map((dropdownContent) => (
                        <a
                          onClick={() => {
                            firebase
                              .analytics()
                              .logEvent(dropdownContent.routeTag);
                            window.fbq(
                              "trackCustom",
                              `${dropdownContent.routeTag}`
                            );

                            window.location.href = dropdownContent.href;
                          }}
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                          {dropdownContent.name}{" "}
                        </a>
                      ))}
                    </div>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  className="nav-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    firebase.analytics().logEvent(route.routeTag);
                    window.fbq("trackCustom", `${route.routeTag}`);
                    window.location.href = route.href;
                  }}
                >
                  <a className="nav-link grey" aria-current="page">
                    {route.name}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  </nav>
);

export class HeroPage extends React.Component {
  constructor(props) {
    super(props);
    this.plansRef = React.createRef();
    this.contactRef = React.createRef();
    this.state = {
      activePage: "HOME",
      buildSelected: null,
      currentActiveImage0: 0,
      currentMainImage: 0,
      mainArray: [InteriorPhotoMain2, Anvil, Medlands, Claris],
      activePlan: "ANVIL",
      contactEmailAddress: "",
      contactName: "",
    };
  }
  shiftCurrentImage() {
    setInterval(() => {
      this.nextImage();
    }, 8000);
  }
  nextImage() {
    if (this.state.currentMainImage < 3) {
      this.setState({
        currentMainImage: this.state.currentMainImage + 1,
      });
    } else {
      this.setState({ currentMainImage: 0 });
    }
  }
  componentDidMount() {
    let elems = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(elems, { inDuration: 300, outDuration: 225 });
    this.shiftCurrentImage();
  }
  toggleActivePage(page) {
    return this.setState({ activePage: page });
  }
  changePlan(plan) {
    this.setState({ activePlan: plan });
  }
  changeEmailAddress(e) {
    this.setState({ contactEmailAddress: e.target.value });
  }
  changeName(e) {
    this.setState({ contactName: e.target.value });
  }
  saveContact = (email, name) => {
    rootStore.pageStore.saveContact({ email: email, name: name });
    return console.log("saved:", rootStore.pageStore.contact);
  };
  changeBuild(build) {
    this.setState({ buildSelected: build });
  }
  detectMob() {
    return ( ( window.innerWidth <= 900 ) && ( window.innerHeight <= 1000 ) );
  }
  render() {
    console.log("firebase:", firebase.apps.length);
    return (
      <div>
        <NavBar toggleActivePage={this.toggleActivePage.bind(this)} />
        <div
          className="mainImageBackground text-white"
          style={{ height: "100%", position: "relative" }}
        >
          <div>
            <div>
              <div style={{ position: "absolute", zIndex: 8999 }}>
                <h2
                  style={{
                    paddingTop: 40,
                    marginLeft: 60,
                    marginRight: "60%",
                    marginBottom: 0,
                  }}
                >
                  {this.props.name}
                </h2>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <>

                  <div                       className={`mb-4 ${this.detectMob()?'mobileOpaqueTextBox':''}`}

                                             style={{
                      padding: 30,
                      minWidth: this.detectMob()?360:300,
                      maxWidth: 400,
                      width: "30%",
                      paddingTop: 0,
                      paddingLeft: 10,
                      paddingRight: 0,
                    }}
                  >
                    <h2 className="h2Content">{titleContent}</h2>
                    <h5
                      style={{ fontSize: 20, marginLeft: 10,padding:this.detectMob()?10:0 }}
                      className="mb-4"
                    >
                      {titleBlurb}
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="altButtonOuter"
                        onClick={() => {
                          firebase.analytics().logEvent("view_contact_btn");
                          this.contactRef.current.scrollIntoView();
                        }}
                      >
                        <div className={`altButton ${this.detectMob()?'mobileOpaqueButton':''}`}>Contact Us</div>
                      </div>
                      <div
                        className="altButtonOuter"
                        style={{ marginRight: this.detectMob()?0:35 }}
                        onClick={() => {
                          firebase.analytics().logEvent("view_range_btn");
                          window.location.href = "/productrange";
                        }}
                      >
                        <div  className={`altButton ${this.detectMob()?'mobileOpaqueButton':''}`}>Our Range</div>
                      </div>
                    </div>
                  </div>
                </>
                  {this.detectMob()&&<div style={{width:370,height:480,position:'absolute',backgroundColor:'grey',opacity:0.7,borderRadius:12}}></div>}

                </div>

              </div>
              <div
                className="blurTest"
                style={{
                  height: this.detectMob()?0:790,
                  minWidth: 380,
                  zIndex: 900,
                  backgroundColor: "#505050",
                  position: "absolute",
                }}
              ></div>
              <div className="myDIV" style={{ minHeight: 900 }}>
                <img
                  onClick={() => {
                    this.nextImage();
                  }}
                  key={this.state.currentMainImage}
                  src={this.state.mainArray[this.state.currentMainImage]}
                  className="fadedshort"
                  style={{ width: "100%", height: 790, objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
          <div
            className="mainImageBackground mainColor"
            style={{ fontSize: 20, paddingBottom: 50 }}
          >
            <div className="container">
              <div style={{ paddingTop: 20 }}>
                <div>
                  {/*   <div className="col-12 col-md-5 offset-md-1" style={{marginLeft:0}}>
                                <img src={dreamwoodMain2} className="d-block mx-auto" height="410" width="900"/>
                            </div>*/}
                  <div
                    className="supportingColor"
                    style={{ padding: 33, opacity: 1 }}
                  >
                    <div className="px-4">

                        <h2 style={{ fontSize: 35 }}>{supportingHeading}</h2>


                      <p
                        style={{
                          borderTop: "1px solid #fff",
                          borderLeft: "1px solid #fff",
                          paddingTop: 10,
                          paddingLeft: 15,
                          fontSize: this.detectMob()?15:20,
                          lineHeight: this.detectMob()?'20px':"38px",
                        }}
                      >
                        {supportingBlurb}
                        <br />
                      </p>
                      <div style={{display:'flex',justifyContent:'center'}}>
                        <div className={`altButton ${this.detectMob()?'informationMobileButton':''}`} style={{width:300,marginRight:40,marginTop:30}} onClick={()=>{
                          this.plansRef.current.scrollIntoView();
                          this.changeBuild("anvil");
                        }}>View our information pack now</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-6 bg-mainColor" id="charity">
            <PlanShowcase
              plansRef={this.plansRef}
              contactEmailAddress={this.state.contactEmailAddress}
              contactName={this.state.contactName}
              changeEmailAddress={this.changeEmailAddress.bind(this)}
              changeName={this.changeName.bind(this)}
              saveContact={this.saveContact.bind(this)}
              changePlan={this.changePlan.bind(this)}
              changeBuild={this.changeBuild.bind(this)}
              buildSelected={this.state.buildSelected}
              activePlan={this.state.activePlan}
            />
            <WisehomeContent />

            <div
              style={{ backgroundColor: "#fff", padding: 60, paddingTop: 20 }}
              className="py-6 text-white position-relative px-4"
              id="timeline"
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 style={{ color: "#505050", marginBottom: 20 }}>
                  Our Trusted Partners
                </h2>
              </div>
              {trustedPartnerContent}
            </div>
            <div
              className="mainImageBackground text-white position-relative"
              id="timeline"
            >
              <div className="container">{secondary}</div>
              <div ref={this.contactRef} style={{ marginTop: 40 }}>
                <div
                  className=""
                  style={{ marginTop: 40, padding: 40, margin: 0 }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <h2
                        style={{
                          fontSize: 56,
                          fontWeight: 400,
                          textAlign: "center",
                          borderBottom: "1px solid #fff",
                          marginBottom: 0,
                        }}
                      >
                        {contactTitle}
                        <br />
                      </h2>
                      <p
                        style={{
                          marginBottom: 30,
                          borderLeft: "1px solid #fff",
                          paddingLeft: 15,
                          paddingTop: 10,
                          width: "60vw",
                          minWidth: 350,
                          fontSize: 20,
                          lineHeight: "38px",
                        }}
                      >
                        {businessBlurb}
                      </p>
                      <div style={{ textAlign: "left" }}>
                        <div>
                          <div style={{ position: "relative" }}>
                            <i
                              style={{ position: "absolute", top: 7, left: 0 }}
                              className="material-icons"
                            >
                              mail
                            </i>{" "}
                          </div>
                          <div style={{ marginLeft: 40, fontSize: 20 }}>
                            <b></b>
                            {contactEmail}
                          </div>
                        </div>
                        <br />
                        <div>
                          <div style={{ position: "relative" }}>
                            <i
                              style={{ position: "absolute", top: 7, left: 0 }}
                              className="material-icons"
                            >
                              local_phone
                            </i>{" "}
                          </div>
                          <div style={{ marginLeft: 40, fontSize: 20 }}>
                            <b></b>
                            {contactPhone}
                          </div>
                        </div>
                        <br />
                      </div>
                      <div>
                        <iframe
                          style={{ height: 900, width: "100%" }}
                          src={mailChimpSource}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
