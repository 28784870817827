import React from "react";
import { NavBar } from "./HeroPage";
import {
  businessBlurb,
  contactEmail,
  contactPhone,
  ProductDescriptionContent,
} from "../content";
import { renderPDFSource } from "../content/PlanShowcase";
import { rootStore } from "../stores/Store";
import cookie from "js-cookie";
import firebase from "firebase/compat";

var request = require("request");

export default class ItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buildSelected: null,
      currentMainImage: 0,
      mainArray: this.props.imageArray,
      contactEmailAddress: "",
      contactName: "",
      contact: null,
    };
  }
  detectMob() {
    return window.innerWidth <= 950 && window.innerHeight <= 950;
  }
  changeEmailAddress(e) {
    this.setState({ contactEmailAddress: e.target.value });
  }
  changeName(e) {
    this.setState({ contactName: e.target.value });
  }
  saveContact = (email, name) => {
    rootStore.pageStore.saveContact({ email: email, name: name });
    this.setState({ contact: { email, name } });
    return console.log("saved:", rootStore.pageStore.contact);
  };
  renderPDFSource(build) {
    console.log("BUILD:", build, this.props.selectableName);
    //todo-cache make sure this reusable component works with the new plans. ive added the default plan there to match the information pack.
    switch (build) {
      default:
        return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
      case "tahi":
        return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/211026%20Tahi%20_%20final%20set%20(3).pdf?alt=media&token=8e1f99ff-a7e2-46a9-93e4-f19cae1598e9";
      case "rua":
        return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/211027-Rua_final-set.pdf?alt=media&token=df87c19d-c43b-4565-8b92-a1d8a8e1ce05";
      case "toru":
        return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/211206%20Toru%20new.pdf?alt=media&token=5acf1d86-5f55-4eb2-b083-3757acb6f8e6";
    }
  }
  nextImage() {
    if (this.state.currentMainImage === this.state.mainArray.length - 1) {
      return this.setState({ currentMainImage: 0 });
    }
    this.setState({
      currentMainImage: this.state.currentMainImage + 1,
    });
  }
  render() {
    console.log(cookie.get("hasContact"), "contact");
    return (
      <div>
        <NavBar />
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          {this.state.buildSelected && (
            <>
              {this.state.contact !== null ||
                (!cookie.get("hasContact") && (
                  <>
                    <div
                      style={{
                        zIndex: 9002,
                        position: "absolute",
                        minWidth: 350,
                        width: "90vw",
                        paddingLeft: 10,
                      }}
                    >
                      <h2 style={{ paddingTop: 60, color: "#fff" }}>
                        Enter your email address to continue.
                      </h2>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          value={this.state.contactName}
                          onChange={(e) => {
                            this.changeName(e);
                          }}
                          type="text"
                          style={{
                            margin: 5,
                            padding: 8,
                            backgroundColor: "#505050",
                            border: "1px solid #fff",
                            color: "#fff",
                            minWidth: 210,
                            marginRight: 20,
                          }}
                          placeholder={"Your first and last name..."}
                        />
                        <input
                          value={this.state.contactEmailAddress}
                          onChange={(e) => {
                            this.changeEmailAddress(e);
                          }}
                          type="text"
                          style={{
                            margin: 5,
                            padding: 8,
                            backgroundColor: "#505050",
                            border: "1px solid #fff",
                            color: "#fff",
                            minWidth: 210,
                            marginRight: 20,
                          }}
                          placeholder={"Your email address..."}
                        />
                      </div>
                      <div
                        onClick={() => {
                          console.log("mob?", this.detectMob());
                          if (this.detectMob()) {
                            console.log("MOB:", this.state.buildSelected);
                            window.location.href = renderPDFSource(
                              this.state.buildSelected
                            );
                          } else {
                            try {
                              firebase
                                .firestore()
                                .collection("contacts")
                                .doc(this.state.contactEmailAddress)
                                .set({
                                  name: this.state.contactName,
                                  email: this.state.contactEmailAddress,
                                })
                                .then(() => {
                                  console.log("Document successfully written!");
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error writing document: ",
                                    error
                                  );
                                });
                              /*const api = `https://api.hubapi.com/contacts/v1/contact/?hapikey=6bf53a0f-aeb9-4999-a88f-0fdf2262cdd7`
                                    var options = {
                                        method: 'POST',
                                        url: 'https://crossorigin.me/https://api.hubapi.com/contacts/v1/contact/',
                                        qs: {hapikey: '6bf53a0f-aeb9-4999-a88f-0fdf2262cdd7`'},
                                        headers:
                                            {
                                                'Content-Type': 'application/json'
                                            },
                                        body:
                                            {
                                                properties:
                                                    [{property: 'email', value: this.state.contactEmailAddress},
                                                        {property: 'name', value: this.state.contactName},
                                                    ]
                                            },
                                        json: true
                                    };

                                    request(options, function (error, response, body) {
                                        console.log(body);
                                    })*/
                            } catch (e) {
                              console.log("e", e);
                            }
                            firebase
                              .analytics()
                              .logEvent(`saveContact_${this.props.name}`, {
                                name: this.state.contactName,
                                emailAddress: this.state.contactEmailAddress,
                              });
                            this.saveContact(
                              this.state.contactEmailAddress,
                              this.state.contactName
                            );
                          }
                        }}
                        className="altButton"
                        style={{ width: 250, marginTop: 20, color: "#fff" }}
                      >
                        Continue
                      </div>
                    </div>
                    <div
                      className="blurTest"
                      style={{
                        position: "absolute",
                        zIndex: 9001,
                        width: "100%",
                        height: "100vh",
                      }}
                    ></div>
                  </>
                ))}
              <div
                style={{
                  position: "absolute",
                  top: 20,
                  backgroundColor: "#323639",
                  height: "auto",
                  width: "auto",
                  zIndex: 9000,
                }}
              >
                <div style={{ width: "100%", height: 30 }}>
                  <div
                    onClick={() => {
                      this.setState({ buildSelected: null });
                    }}
                    style={{
                      float: "right",
                      cursor: "pointer",
                      paddingRight: 5,
                      paddingTop: 5,
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: 900,
                    }}
                  >
                    <i className="material-icons">close</i>
                  </div>
                </div>
                <div
                  style={{
                    padding: 5,
                    paddingTop: 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {this.detectMob() ? null : (
                    <embed
                      src={renderPDFSource(this.state.buildSelected)}
                      width="1200"
                      height="900"
                      type="application/pdf"
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="mainImageBackground text-white"
          style={this.detectMob()?{
              height:1090,
              paddingTop: 0,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
          }:{
            paddingTop: 0,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              width: "100%",
              position: "relative",
              minHeight: 805,
            }}
          >
            <div style={this.detectMob()?{ marginTop:280,position: "absolute", width: 400, zIndex: 8999 }:{ position: "absolute", width: 400, zIndex: 8999 }}>
              <h2
                style={{
                  paddingTop: 40,
                  marginLeft: 60,
                  borderBottom: "1px solid #fff",
                  marginRight: "60%",
                  marginBottom: 0,
                }}
              >
                {this.props.name}
              </h2>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    padding: 30,
                    minWidth: 300,
                    maxWidth: 400,
                    width: "30%",
                    paddingTop: 0,
                    paddingLeft: 10,
                    paddingRight: 0,
                  }}
                >
                  <ProductDescriptionContent
                    description={this.props.description}
                    name={this.props.name}
                    price={this.props.price}
                    buttonAction={() => {
                      firebase
                        .analytics()
                        .logEvent(`view_plans_${this.props.name}`);
                      window.fbq("trackCustom", "View_Plan", {
                        product: this.props.name.toLowerCase(),
                      });

                      this.setState({
                        buildSelected: this.props.selectableName,
                      });
                    }}
                    buttonContent={"View information pack"}
                  />
                </div>
              </div>
            </div>
            <div
              className="blurTest"
              style={this.detectMob()?{
                  marginTop:280,
                  height: 810,
                  width: 400,
                  zIndex: 900,
                  backgroundColor: "#505050",
                  position: "absolute",}:{
                  marginTop:0,
                height: 810,
                width: 400,
                zIndex: 900,
                backgroundColor: "#505050",
                position: "absolute",
              }}
            ></div>
            <div
              style={{ maxHeight: 800, minHeight: 500, paddingBottom: 10 }}
              className="myDIV"
              onClick={() => {
                this.nextImage();
              }}
            >
              <img
                style={{
                  marginLeft: 2,
                  marginTop: 2,
                  marginBottom: 15,
                  maxHeight: 1000,
                }}
                key={this.state.currentMainImage}
                src={this.state.mainArray[this.state.currentMainImage]}
                className="hoverImageNext fadedshort"
                width={"99.9%"}
              />
            </div>
            <div
              className="imageRight portfolioImage hide"
              style={{ display: "inline-grid" }}
            >
              <i
                onClick={() => {
                  this.nextImage();
                }}
                style={{
                  fontSize: 72,
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#505050",
                  borderRadius: 12,
                }}
                className="large material-icons"
              >
                keyboard_arrow_right
              </i>
              <span
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  marginTop: 5,
                  paddingLeft: 5,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#505050",
                }}
              >
                {this.state.currentMainImage + 1} /{" "}
                {this.props.imageArray.length}
              </span>
            </div>
          </div>
        </div>
        <div
          className="mainImageBackground text-white"
          style={{ paddingBottom: 0 }}
        >
          <div
            style={{
              padding: 20,
              backgroundColor: "#505050",
              opacity: 1,
              borderRadius: 4,
            }}
          >
            <div className="container">
              <div style={{ marginTop: 20 }}></div>
              <div
                style={{
                  padding: 25,
                  marginBottom: 0,
                  backgroundColor: "#505050",
                  paddingBottom: 100,
                  border: "1px solid #fff",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    <h2 style={{ fontSize: 56, fontWeight: 400 }}>
                      Like the look? <br />
                    </h2>
                    <p>{businessBlurb}</p>
                  </div>
                  <div style={{ textAlign: "left", paddingTop: 35 }}>
                    <div>
                      <div style={{ position: "relative" }}>
                        <i
                          style={{ position: "absolute", top: 0, left: 0 }}
                          className="material-icons"
                        >
                          mail
                        </i>{" "}
                      </div>
                      <div style={{ marginLeft: 40 }}>
                        <b></b>
                        {contactEmail}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div style={{ position: "relative" }}>
                        <i
                          style={{ position: "absolute", top: 0, left: 0 }}
                          className="material-icons"
                        >
                          local_phone
                        </i>{" "}
                      </div>
                      <div style={{ marginLeft: 40 }}>
                        <b></b>
                        {contactPhone}
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                  className="altButton"
                  style={{ maxWidth: 300, marginTop: 20, textAlign: "center" }}
                >
                  Make an enquiry
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
