//main
import AJ from "./images/AJ.jpeg";
import CS from "./images/CS.jpeg";
import LBP from "./images/LBP-logo.jpeg";
import LTR from "./images/Life-Time-Roofing-logo.jpeg";
import RMB from "./images/RMB-logo.jpeg";
import SS from "./images/SiteSafe-logo.jpeg";
import logo from "./images/DW_logos_reversed.png";
import ITM from "./images/ITM.png";
import NZM from "./images/NZM.png";
import NZTH from "./images/NZTH.png";

//todo-cache update route items with new plans, replace tahi rua toru with the new ones
export const RouteItems = [
  { name: "Home", href: "/", routeTag: "view_home_nav" },
  {
    name: "Product Range",
    href: "/productrange",
    routeTag: "view_range_nav",
    dropArray: [
      { name: "Anvil", href: "/anvil", routeTag: "view_anvil_nav" },
      { name: "Awana", href: "/awana", routeTag: "view_awana_nav" },
      { name: "Blind Bay", href: "/blindbay", routeTag: "view_blindbay_nav" },
      { name: "Claris", href: "/claris", routeTag: "view_claris_nav" },
      { name: "Fitzroy", href: "/fitzroy", routeTag: "view_fitzroy_nav" },
      { name: "Medlands", href: "/medlands", routeTag: "view_medlands_nav" },

      {
        name: "Schooner Bay",
        href: "/schoonerbay",
        routeTag: "view_schoonerbay_nav",
      },
      { name: "Tryphena", href: "/tryphena", routeTag: "view_tryphena_nav" },
    ],
  },
  { name: "Gallery", href: "/gallery", routeTag: "view_gallery_nav" },
  { name: "Our Process", href: "/process", routeTag: "view_process_nav" },
  { name: "About Us", href: "/about", routeTag: "view_about_nav" },
  { name: "Contact Us", href: "/contact", routeTag: "view_contact_nav" },
];

export const titleContent = `The Perfect Addition.`;
export const titleBlurb = `We are in the business of providing you with quality workmanship and tiny home solutions.`;
export const supportingHeading =
  "Dreamwood Tiny Homes are your tiny home specialists.";
export const supportingBlurb = `Expertise delivered with 40+ years of experience in all aspects of
                                        construction.\n
                                        Using highly skilled tradesmen, licensed master builders and NZ sourced materials
                                        we aim to provide excellent quality and a hassle-free experience for all our
                                        builds. \n Offering superior warmth, comfort, and energy-efficient. Our modern
                                        architecturally designed minor dwellings also come with a 10-year warranty. We are your tiny home specialists.`;

//todo-cache - update this content with the newly sourced images. (just add them in, make it scroll if needed)
export const trustedPartnerContent = (
  <div
    style={{
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    }}
  >
    <img src={AJ} />
    <img src={CS} />
    <img src={LBP} />
    <img src={LTR} />
    <img src={RMB} />
    <img src={SS} />
    <img src={ITM} />
    <img src={NZM} />
    <img src={NZTH} />
  </div>
);
export const secondaryContentTitle = "Peace of mind Guarantee";
export const secondaryContent =
  "All Dreamwood Tiny Homes are built and signed off by a licensed building practitioner to\n" +
  " NZ:3604 with options to upgrade to sea spray zone, extra high wind zones.\n" +
  "All units come with an electrical compliance certificate, plumbing certificates, and\n" +
  "materials warranty where applicable.";
export const secondary = (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-start",
      paddingTop: 40,
      flexWrap: "wrap",
      marginLeft: "12%",
    }}
  >
    <div style={{ minWidth: 300, display: "flex", justifyContent: "center" }}>
      <img
        style={{ paddingTop: 45, paddingRight: 20 }}
        src={logo}
        alt=""
        width="180"
      />
    </div>
    <div style={{ width: "55%", minWidth: 300 }}>
      <h3
        style={{
          borderBottom: "1px solid #fff",
          marginRight: "5%",
          marginBottom: 0,
        }}
      >
        {secondaryContentTitle}
      </h3>
      <p
        style={{
          fontSize: 18,
          borderLeft: "1px solid #fff",
          paddingLeft: 15,
          paddingTop: 10,
        }}
      >
        {secondaryContent}
      </p>
    </div>
  </div>
);

//other

export const businessBlurb = `Dreamwood Tiny Homes are custom manufacturers of precision-built prefabricated tiny homes. 
Expertise delivered with 40+ years of experience in all aspects of construction. 
Call us right now or make an enquiry, we look forward to hearing from you.`;
export const contactTitle = "Get In Touch";
export const contactEmail = "alex@dreamwoodhomes.co.nz";
export const contactPhone = "022 191 0820";
export const mailChimpSource =
  "https://us20.list-manage.com/contact-form?u=2bc29fbcfb3eb2dab002db0dc&form_id=31e299f9b79624abc57a5720b0e551f3";
export const ProductDescriptionContent = (props) => (
  <>
    <p
      className="scrollbar"
      style={{
        height: 500,
        borderLeft: "1px solid #fff",
        paddingLeft: 12,
        paddingTop: 10,
        maxHeight: 600,
        overflowY: "auto",
        paddingRight: 20,
      }}
    >
      {props.description}
      <br />
      <br />
      <h4>Inclusions</h4>
      <ul>
        <li className="inclusionList"> Double glazed joinery</li>
        <li className="inclusionList">
          {" "}
          Large joinery units to the front of all units 2.4 high
        </li>
        <li className="inclusionList">
          {" "}
          Englefield lined shower (tiled shower optional)
        </li>
        <li className="inclusionList">
          {" "}
          Greens tapware throughout black or brushed chrome
        </li>
        <li className="inclusionList"> Kohler toilet (composting optional)</li>
        <li className="inclusionList">
          {" "}
          900 x 400 Vanity unit with mirror above
        </li>
        <li className="inclusionList">
          {" "}
          Eco downlights to complete building and front soffit
        </li>
        <li className="inclusionList">
          {" "}
          Overlay engineered plank through all units
        </li>
        <li className="inclusionList">
          {" "}
          Gas califont or optional hot water cylinder
        </li>
        <li className="inclusionList">
          {" "}
          Weathertex 300mm architectural panel as cladding
        </li>
        <li className="inclusionList">
          {" "}
          Smeg oven and cook top (or the equivalent of)
        </li>
        <li className="inclusionList">
          {" "}
          Laminex acrylic panel bench top in kitchen
        </li>
        <li className="inclusionList">
          {" "}
          Complete kitchen cabinetry with soft close draws
        </li>
      </ul>
    </p>{" "}
    {props.price
      ? `Price starting at ${props.price} +GST`
      : "Price on request"}{" "}
    <br />
    <div
      className="altButton"
      style={{ cursor: "pointer", marginTop: 20 }}
      onClick={() => {
        props.buttonAction();
      }}
    >
      {props.buttonContent}
    </div>
  </>
);
