import React from "react";
import firebase from "firebase/compat";
import {NavBar} from "./HeroPage";
import person from "../images/person.jpeg";

//todo-cache update photos with alex and john (tbd)
export class About extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        console.log('firebase:', firebase.apps.length);
        return (<div>
            <NavBar />
            <div className="mainImageBackground" style={{paddingBottom:100,height:'100vh'}}>
                <div style={{display:'flex',justifyContent:'center'}}><div style={{width:'40%'}}><h1 style={{borderBottom:'1px solid #fff',textAlign:'center',paddingTop:20,color:'#fff',marginBottom:60}}>
                   The Dreamwood Team</h1></div></div>
                <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                    <div className="col-12 col-md-5 offset-md-1">
                        <br />
                        <p style={{paddingTop:0,color:'#fff'}}>
                            <h1>Alex Anderson</h1>
                            <h3 style={{borderBottom:'1px solid #fff'}}>Director</h3>

                            Alex has been working in the construction industry for sixteen years. Nine of these have been for his construction and roofing companies.

                            Alex spent the majority of this time working in the high-end sector.

                            John and Alex started Dreamwood Tiny Homes to bring our high-end custom build market skills to the prefabricated sector.

                            We know there is a housing shortage in New Zealand and want to help by building quality smaller homes within reach of everyday kiwis.

                        </p>

                    </div>
                    <div className="col-12 col-md-5" style={{color:'#fff'}}>
                        <div className="px-4">
                            <h1 style={{marginTop:20}}>John Overington</h1>
                            <h3 style={{borderBottom:'1px solid #fff'}}>Director</h3>

                            <p style={{color:'#fff'}}>John is a licensed builder and master builder with 28 years of experience. His knowledge, eye for detail and management skills make him stand out in the construction industry.

                            John believes in creating a happy, safe and learning environment for all his employees and contractors.

                            He has built solid relationships with contractors throughout the years, working with the very best.

                                John is a family man and enjoys fishing and all sports in his downtime.</p>{/*<p>
                           *leave gareth off the website for now

                           Gareth Clark
                           National Sales Director

                           Gareth has over seven years of professional sales experience working directly with clients to ensure consistency and a smooth transition from initial contact to project completion.

                           His background in residential property management allows him to help advise on how to get the most out of your additional dwelling.

                           Strong communication skills and attention to detail will ensure that you always have the answers you need.

                           Please get in touch with Gareth today if you would like to know more about Dreamwood homes and how we can help make your minor dwelling dream a reality.
                       </p>*/}
                        </div>
                    </div>
                </div>
                <div style={{paddingTop:120,color:'#fff'}}>
                    <h2 style={{textAlign:'center'}}>Ready to make the call?</h2>
                    <p style={{textAlign:'center'}}>We offer custom plans and fabrication, get in touch to see if we can make your dream happen.</p>
                    <div style={{display:'flex',justifyContent:'center'}}><div onClick={()=>{window.location.href='/contact'}} className="altButton" style={{width:300,marginTop:20,textAlign:'center'}}>
                        Make an enquiry

                    </div>
                    </div>

                </div>
            </div>
        </div>);
    }
}

