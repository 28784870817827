import {
    action, autorun, observable, toJS,
} from 'mobx';
import {persist} from "mobx-persist";
import cookie from 'js-cookie';
import firebase from "firebase/compat";


export default class PageStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable value = '';
    @persist @observable contact = null;
    @action.bound setValue(valueToSet){
        this.value=valueToSet
    }
    @action.bound saveContact(contact){
        firebase.analytics().setUserProperties( { email: contact.email,name:contact.name });
        window.fbq('trackCustom', 'Details_Entered', {name: contact.name,email:contact.email});
        cookie.set('hasContact','true');
        this.contact=contact
    }

}