import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { inject, observer } from "mobx-react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { HeroPage } from "./pages/HeroPage";
import { Portfolio } from "./pages/Portfolio";
import { Admin } from "./pages/Admin";
import { Process } from "./pages/Process";
import ItemPage from "./pages/ItemPage";
import { Contact } from "./pages/Contact";
import firebase from "firebase/compat";
import anvilRender from "./images/update/Anvil.jpg";
import anvilPlan from "./images/update/AnvilPlan.jpg";
import awanaRender from "./images/update/Awana.jpg";
import awanaPlan from "./images/update/AwanaPlan.jpg";
import blindBayRender from "./images/update/BlindBay.jpg";
import blindBayPlans from "./images/update/BlindBayPlans.jpg";
import clarisRender from "./images/update/Claris.jpg";
import clarisPlan from "./images/update/ClarisPlan.jpg";
import fitzroyRender from "./images/update/Fitzroy.jpg";
import fitzroyPlan from "./images/update/FitzroyPlan.jpg";
import medlandsRender from "./images/update/Medlands.jpg";
import medlandsPlan from "./images/update/MedlandsPlan.jpg";
import schoonerBayRender from "./images/update/SchoonerBay.jpg";
import schoonerBayPlan from "./images/update/SchoonerBayPlan.jpg";
import tryphenaRender from "./images/update/Tryphena.jpg";
import tryphenaPlan from "./images/update/TryphenaPlan.jpg";
import { ProductRange } from "./pages/ProductRange";
import { About } from "./pages/About";

const customHistory = createBrowserHistory();

@inject("rootStore")
@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    const firebaseConfig = {
      apiKey: "AIzaSyDUDDvrLuNauhzc9obx2OI2fG-NezZTv1k",
      authDomain: "dreamwoodhomes-83cc6.firebaseapp.com",
      projectId: "dreamwoodhomes-83cc6",
      storageBucket: "dreamwoodhomes-83cc6.appspot.com",
      messagingSenderId: "734630250913",
      appId: "1:734630250913:web:4a20f7a13e1faf87e863e0",
      measurementId: "G-JJFZFHXC3Z",
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }
  render() {
    return (
      <Router history={customHistory}>
        <Switch>
          <Route path="/" exact component={HeroPage} />
          <Route path="/portfolio" exact component={Portfolio} />
          <Route path="/process" exact component={Process} />
          <Route path="/productrange" exact component={ProductRange} />
          <Route
            path="/anvil"
            exact
            component={() => (
              <ItemPage
                name={"Anvil"}
                selectableName={"anvil"}
                imageArray={[anvilRender, anvilPlan]}
                price={null}
                description={
                  "Anvil is our 30m2 studio, creating one large common area to gather in. This unit has high ceilings, kitchenette and complete bathroom."
                }
              />
            )}
          />
          <Route
            path="/awana"
            exact
            component={() => (
              <ItemPage
                name={"Awana"}
                selectableName={"awana"}
                imageArray={[awanaRender, awanaPlan]}
                price={null}
                description={
                  "Awana is our 24m2 studio, with a large common area, high ceilings, full kitchen and bathroom."
                }
              />
            )}
          />
          <Route
            path="/blindbay"
            exact
            component={() => (
              <ItemPage
                name={"Blind Bay"}
                selectableName={"blind bay"}
                imageArray={[blindBayRender, blindBayPlans]}
                price={null}
                description={
                  "Blind Bay is our 21m2 unit, including high ceilings, full kitchen, lounge and bathroom."
                }
              />
            )}
          />
          <Route
            path="/claris"
            exact
            component={() => (
              <ItemPage
                name={"Claris"}
                selectableName={"claris"}
                imageArray={[clarisRender, clarisPlan]}
                price={null}
                description={
                  "Claris is our 38m2 design hosting two bedrooms, high ceilings, bathroom, lounge, full kitchen and external laundry. There are many uses for our two bedroom units, air-bnb, additional rental income, or holiday home. Our two bedroom units are commonly requested."
                }
              />
            )}
          />
          <Route
            path="/fitzroy"
            exact
            component={() => (
              <ItemPage
                name={"Fitzroy"}
                selectableName={"fitzroy"}
                imageArray={[fitzroyRender, fitzroyPlan]}
                price={null}
                description={
                  "Fitzroy is our 30m2 unit, and has been designed as a narrower option with a central bathroom. The central bathroom gives separation between the living area and bedroom. Includes; high ceilings, full kitchen, lounge, bathroom and separate external laundry."
                }
              />
            )}
          />
          <Route
            path="/medlands"
            exact
            component={() => (
              <ItemPage
                name={"Medlands"}
                selectableName={"medlands"}
                imageArray={[medlandsRender, medlandsPlan]}
                price={null}
                description={
                  "Medlands is one of our signature plans and a flip of our Schooner bay design. 30m2 one bedroom unit, high ceilings, full kitchen, lounge and bathroom."
                }
              />
            )}
          />
          <Route
            path="/schoonerbay"
            exact
            component={() => (
              <ItemPage
                name={"Schooner Bay"}
                selectableName={"schooner bay"}
                imageArray={[schoonerBayRender, schoonerBayPlan]}
                price={null}
                description={
                  "Schooner Bay is one of our signature plans. 30m2 one bedroom unit, high ceilings, full kitchen, lounge and bathroom."
                }
              />
            )}
          />
          <Route
            path="/tryphena"
            exact
            component={() => (
              <ItemPage
                name={"Tryphena"}
                selectableName={"tryphena"}
                imageArray={[tryphenaRender, tryphenaPlan]}
                price={null}
                description={
                  "Anvil is our 30m2 studio, creating one large common area to gather in. This unit has high ceilings, kitchenette and complete bathroom."
                }
              />
            )}
          />
          <Route path="/contact" exact component={Contact} />
          <Route path="/about" exact component={About} />
          <Route path="/gallery" exact component={Portfolio} />
          <Route path="/administrator" exact component={Admin} />
        </Switch>
      </Router>
    );
  }
}

export default App;
