import home_icon from "../images/realtor-icon-4-dark.png";
import firebase from "firebase/compat";
import { rootStore } from "../stores/Store";
import cookie from "js-cookie";
var request = require("request");

function detectMob() {
    return ( ( window.innerWidth <= 900 ) && ( window.innerHeight <= 1000 ) );
}
export function renderPDFSource(build) {
  //todo-cache update plans here with information pack, replace 'view plans' with 'view information pack'
  //replace the 3 Tahi Rua Toru, with the 8 Plan names eg "Anvil"
  //as named in /content/ folder. Images to come, for now use plan image in content folder as placeholder.
  //Just copy paste how i did it with the inital 3 plans.
  //link to information pack below
  //https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f
  switch (build) {
    default:
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "anvil":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "awana":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "blindbay":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "claris":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "fitzroy":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "medlands":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "schoonerbay":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
    case "tryphena":
      return "https://firebasestorage.googleapis.com/v0/b/dreamwoodhomes-83cc6.appspot.com/o/Dreamwood%20Homes%20(3).pdf?alt=media&token=b2dd87c6-870c-4dc2-8b2f-19c8d6bccd5f";
  }
}
let anvilDescription = <div>Anvil is our 30m<sup>2</sup> studio, creating one large common area to gather in. This unit has high ceilings, kitchenette and complete bathroom. <br />Click the image to learn more.</div>;
let awanaDescription = <div>Awana is our 24m<sup>2</sup>  studio, with a large common area, high ceilings, full kitchen and bathroom. <br />Click the image to learn more.</div>
let blindBayDescription = <div>Blind Bay is our 21m<sup>2</sup>  unit, including high ceilings, full kitchen, lounge and bathroom. <br />Click the image to learn more.</div>
let clarisDescription = <div>Claris is our 38m<sup>2</sup>  design hosting two bedrooms, high ceilings, bathroom, lounge, full kitchen and external laundry.<br/>
There are many uses for our two bedroom units, air-bnb, additional rental income, or holiday home. Our two bedroom units are commonly requested. <br />Click the image to learn more.</div>
let fitzroyDescription = <div>Fitzroy is our 30m<sup>2</sup>  unit, and has been designed as a narrower option with a central bathroom. The central bathroom gives separation between the living area and bedroom.<br />
Includes; high ceilings, full kitchen, lounge, bathroom and separate external laundry. <br />Click the image to learn more.</div>
let medlandsDescription = <div>Medlands is one of our signature plans and a flip of our Schooner bay design. 30m<sup>2</sup>  one bedroom unit, high ceilings, full kitchen, lounge and bathroom. <br />Click the image to learn more.</div>
let schoonerbayDescription = <div>Schooner Bay is one of our signature plans.  30m<sup>2</sup>  one bedroom unit, high ceilings, full kitchen, lounge and bathroom. <br />Click the image to learn more.</div>
let tryphenaDescription = <div>Tryphena, is our 38m<sup>2</sup>  design hosting two bedrooms, high ceilings, central bathroom, lounge and full kitchen. <br />
There are many uses for our two bedroom units, air-bnb, additional rental income, or holiday home. Our two bedroom units are commonly requested. <br />Click the image to learn more.</div>


function renderActivePlan(activePlan, plansRef, changeBuild, buildSelected) {
  switch (activePlan) {
    case "ANVIL":
      return (
        <div style={{ position: "relative" }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              padding: 0,
              width: "100%",
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/anvil'}}
                style={{
                    backgroundColor: "#4f4f4f",
                    border: "1px solid #b5926a",
                    margin: 15,
                    padding: 30,
                    opacity: 1,
                }}
              className="persistOpacity"
            >
              <h2>
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                ANVIL{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {anvilDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                    e.stopPropagation();e.preventDefault();

                    firebase.analytics().logEvent("view_plan_anvil");
                  window.fbq("trackCustom", "View_Plan", { product: "anvil" });

                  plansRef.current.scrollIntoView();
                  changeBuild("anvil");
                }}
              >
                View information pack
              </p>

            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/anvil";
            }}
            key={0}
            className={`homeImage px-4 faded anvilImage`}
            style={{
              zIndex: 9999,
              width: "100%",
              height: 500,
              cursor: "pointer",
            }}
          ></div>
        </div>
      );
    case "AWANA":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/awana'}}

                style={{
                    backgroundColor: "#4f4f4f",
                    border: "1px solid #b5926a",
                    margin: 15,
                    padding: 30,
                    opacity: 1,
                }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                AWANA{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {awanaDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                    e.stopPropagation();e.preventDefault();
                  firebase.analytics().logEvent("view_plan_rua");
                  window.fbq("trackCustom", "View_Plan", { product: "awana" });

                  plansRef.current.scrollIntoView();
                  changeBuild("awana");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/awana";
            }}
            key={1}
            className={`homeImage px-4 faded awanaImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
    case "BLIND BAY":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/blindbay'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                BLIND BAY{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {blindBayDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("view_plan_blindbay");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "blindbay",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("blindbay");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/blindbay";
            }}
            key={2}
            className={`homeImage px-4 faded blindBayImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
    case "CLARIS":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/claris'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                CLARIS{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {clarisDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("view_plan_claris");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "claris",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("claris");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/claris";
            }}
            key={2}
            className={`homeImage px-4 faded clarisImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );

    case "FITZROY":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/fitzroy'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                FITZROY{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {fitzroyDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("view_plan_fitzroy");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "fitzroy",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("fitzroy");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/fitzroy";
            }}
            key={2}
            className={`homeImage px-4 faded fitzroyImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
    case "MEDLANDS":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/medlands'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                MEDLANDS{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {medlandsDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("medlands");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "medlands",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("medlands");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/medlands";
            }}
            key={2}
            className={`homeImage px-4 faded medlandsImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
    case "SCHOONER BAY":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/schoonerbay'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                SCHOONER BAY{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {schoonerbayDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("schoonerbay");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "schoonerbay",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("schoonerbay");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/schoonerbay";
            }}
            key={2}
            className={`homeImage px-4 faded schoonerBayImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
    case "TRYPHENA":
      return (
        <div style={{ position: "relative", paddingTop: 0 }}>
          <div
            className="persistOpacity"
            style={{
              backgroundColor: "transparent",
              height: 500,
              marginBottom: 100,
              width: "100%",
              padding: 0,
              maxWidth: 500,
              position: "absolute",
              zIndex: 99999,
            }}
          >
            <div
                onClick={()=>{window.location.href='/tryphena'}}

                style={{
                backgroundColor: "#4f4f4f",
                border: "1px solid #b5926a",
                margin: 15,
                padding: 30,
                opacity: 1,
              }}
              className="persistOpacity"
            >
              <h2 className="text-center">
                <img
                  style={{ marginRight: 30, marginBottom: 10 }}
                  src={home_icon}
                />{" "}
                TRYPHENA{" "}
              </h2>

              <p style={{ fontSize: 18 }}>
                  {tryphenaDescription}
              </p>
              <p
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#fff",
                  cursor: "pointer",
                }}
                onClick={(e) => {e.stopPropagation(); e.preventDefault();
                  firebase.analytics().logEvent("tryphena");
                  window.fbq("trackCustom", "View_Plan", {
                    product: "tryphena",
                  });

                  plansRef.current.scrollIntoView();
                  changeBuild("tryphena");
                }}
              >
                View Information Pack
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              window.location.href = "/tryphena";
            }}
            key={2}
            className={`homeImage px-4 faded tryphenaImage`}
            style={{
              cursor: "pointer",
              zIndex: 9999,
              width: "100%",
              height: 500,
            }}
          ></div>
        </div>
      );
  }
}

export const PlanShowcase = (props) => {
  return (
    <>
      <div>
        <h2 style={{ fontSize: detectMob()?28:56, fontWeight: 100, textAlign: "center" }}>
          Our Product Range{" "}
        </h2>
        <p className="mb-4" style={{ fontSize: detectMob()?18:24, textAlign: "center" }}>
          Our premium dwellings come in 8 spacious sizes, to suit every need,
          plan and vision. <br />
        All units are built on a steel frame, trailers are optional depending on personal requirements.

        </p>
      </div>
      <div
        ref={props.plansRef}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          paddingTop: 15,
        }}
      >
        {props.buildSelected && (
          <>
            {!cookie.get("hasContact") && (
              <>
                <div
                  style={{
                    zIndex: 9002,
                    position: "absolute",
                    minWidth: 350,
                    width: "90vw",
                    paddingLeft: 10,
                  }}
                >
                  <h2 style={{ paddingTop: 60 }}>
                    Enter your email address to continue.
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <input
                      value={props.contactName}
                      onChange={(e) => {
                        props.changeName(e);
                      }}
                      type="text"
                      style={{
                        margin: 5,
                        padding: 8,
                        backgroundColor: "#505050",
                        border: "1px solid #fff",
                        color: "#fff",
                        minWidth: 210,
                        marginRight: 20,
                      }}
                      placeholder={"Your first and last name..."}
                    />
                    <input
                      value={props.contactEmailAddress}
                      onChange={(e) => {
                        props.changeEmailAddress(e);
                      }}
                      type="text"
                      style={{
                        margin: 5,
                        padding: 8,
                        backgroundColor: "#505050",
                        border: "1px solid #fff",
                        color: "#fff",
                        minWidth: 210,
                        marginRight: 20,
                      }}
                      placeholder={"Your email address..."}
                    />
                  </div>
                  <div
                    onClick={() => {
                      console.log("mob?", detectMob());
                      if (detectMob()) {
                        console.log("MOB:", props.activePlan.toLowerCase());
                        window.location.href = renderPDFSource(
                          props.activePlan.toLowerCase()
                        );
                      } else {
                        try {
                          firebase
                            .firestore()
                            .collection("contacts")
                            .doc(props.contactEmailAddress)
                            .set({
                              name: props.contactName,
                              email: props.contactEmailAddress,
                            })
                            .then(() => {
                              console.log("Document successfully written!");
                            })
                            .catch((error) => {
                              console.error("Error writing document: ", error);
                            });
                        } catch (e) {
                          console.log("e", e);
                        }
                        props.saveContact(
                          props.contactEmailAddress,
                          props.contactName
                        );
                      }
                    }}
                    className="altButton"
                    style={{ width: 250, marginTop: 20, textAlign: "center" }}
                  >
                    Continue
                  </div>
                </div>
                <div
                  className="blurTest"
                  style={{
                    position: "absolute",
                    zIndex: 9001,
                    width: "100%",
                    height: "100vh",
                  }}
                ></div>
              </>
            )}
            <div
              style={{
                position: "absolute",
                top: 20,
                backgroundColor: "#323639",
                height: "auto",
                width: "auto",
                zIndex: 9000,
              }}
            >
              <div style={{ width: "100%", height: 30 }}>
                <div
                  onClick={() => {
                    props.changeBuild(null);
                  }}
                  style={{
                    float: "right",
                    cursor: "pointer",
                    paddingRight: 5,
                    paddingTop: 5,
                    color: "#fff",
                    fontSize: 20,
                    fontWeight: 900,
                  }}
                >
                  <i className="material-icons">close</i>
                </div>
              </div>
              <div
                style={{
                  padding: 5,
                  paddingTop: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {detectMob() ? null : (
                  <embed
                    src={renderPDFSource(props.buildSelected)}
                    width="1200"
                    height="900"
                    type="application/pdf"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="container"
        style={{ opacity: props.buildSelected ? 0.5 : 1, height: 890 }}
      >
        {/*These are the buttons that change the plan!*/}
        <div className="row align-items-center" style={
            {display:'flex',justifyContent:'center'}
        }>
          <div
            style={
                detectMob()?
                    {marginBottom: 40,
                        display:'flex',justifyContent:'flex-start',maxWidth:390,flexWrap:'nowrap',overflowX:'auto'}
                    :
                {
              display: "flex",
              justifyContent: "center",
              marginBottom: 40,
              flexWrap: "wrap",
                maxWidth:1100,
            }}
          >
            <div
              onClick={() => {
                props.changePlan("ANVIL");
              }}
              className={`altButton ${
                props.activePlan === "ANVIL" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              ANVIL
            </div>
            <div
              onClick={() => {
                props.changePlan("AWANA");
              }}
              className={`altButton ${
                props.activePlan === "AWANA" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              AWANA
            </div>
            <div
              onClick={() => {
                props.changePlan("BLIND BAY");
              }}
              className={`altButton ${
                props.activePlan === "BLIND BAY" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              BLIND BAY
            </div>
            <div
              onClick={() => {
                props.changePlan("CLARIS");
              }}
              className={`altButton ${
                props.activePlan === "CLARIS" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              CLARIS
            </div>

            <div
              onClick={() => {
                props.changePlan("FITZROY");
              }}
              className={`altButton ${
                props.activePlan === "FITZROY" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              FITZROY
            </div>
            <div
              onClick={() => {
                props.changePlan("MEDLANDS");
              }}
              className={`altButton ${
                props.activePlan === "MEDLANDS" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              MEDLANDS
            </div>
            <div
              onClick={() => {
                props.changePlan("SCHOONER BAY");
              }}
              className={`altButton schoonerBayTextAdjust ${
                props.activePlan === "SCHOONER BAY" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              SCHOONER BAY
            </div>
            <div
              onClick={() => {
                props.changePlan("TRYPHENA");
              }}
              className={`altButton ${
                props.activePlan === "TRYPHENA" && "planSelected"
              }`}
              style={{
                backgroundColor: "#27302b",
                border: "1px solid #535954",
                margin: 15,
                padding: 30,
                opacity: 1,
                width: 200,
                textAlign: "center",
                fontSize: 16,
                height: 86,
              }}
            >
              TRYPHENA
            </div>
          </div>

          <div>
            {renderActivePlan(
              props.activePlan,
              props.plansRef,
              props.changeBuild,
              props.buildSelected
            )}
          </div>
        </div>
      </div>
    </>
  );
};
