import React from 'react';
import person from '../images/person.jpeg';
import mail from '../images/mail.png';
import tahi from '../images/newRoof.png';
import tahi2 from '../images/tahi.jpeg';
import rua from '../images/reroof.jpeg';
import toru from '../images/roofmaintenance.png';
import dreamwoodMain from '../images/dwmain2.png';
import dreamwoodMain2 from '../images/toru2.png';
import inside from '../images/inside.png';
import AJ from '../images/AJ.jpeg';
import CS from '../images/CS.jpeg';
import LBP from '../images/LBP-logo.jpeg';
import LTR from '../images/Life-Time-Roofing-logo.jpeg';
import RMB from '../images/RMB-logo.jpeg';
import SS from '../images/SiteSafe-logo.jpeg';
import Schneider from '../images/selt3.png';
import home_icon from '../images/realtor-icon-4-dark.png';
import logo from '../images/DW_logos_reversed.png';
import firebase from "firebase/compat";
import {NavBar} from "./HeroPage";
import {businessBlurb, contactEmail, contactPhone, contactTitle, mailChimpSource} from "../content";

export class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            buildSelected:null,
        }
    }
    render(){
        return <div>
            <NavBar/>
            <div className="mainImageBackground text-white">
                <div ref={this.contactRef} style={{marginTop:0}}>
                    <div className="" style={{marginTop:40,padding:40,margin:0}}>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <div>
                                <h2 style={{fontSize:56,fontWeight:400,textAlign:'center',borderBottom:'1px solid #fff',marginBottom:0}}>{contactTitle}<br /></h2>
                                <p style={{marginBottom:30,borderLeft:'1px solid #fff',paddingLeft:15,paddingTop:10,width:'60vw',minWidth:350,fontSize:22,lineHeight:'38px'}}>
                                    {businessBlurb}
                                </p>
                                <div style={{textAlign:'left'}}>
                                    <div><div style={{position:'relative'}}><i style={{position:'absolute',top:7,left:0}} className="material-icons">mail</i> </div><div style={{marginLeft:40,fontSize:24}}><b></b>{contactEmail}</div></div>
                                    <br />
                                    <div><div style={{position:'relative'}}><i style={{position:'absolute',top:7,left:0}} className="material-icons">local_phone</i> </div><div style={{marginLeft:40,fontSize:24}}><b></b>{contactPhone}</div></div>
                                    <br />

                                </div>
                                <div >
                                    <iframe style={{height:900,width:'100%'}} src={mailChimpSource} />

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    }
}