import Schneider from "../images/selt3.png";

export const WisehomeContent = ()=>    <div className="mainImageBackground">
    <div className="container">
        <h1 style={{fontSize:56,textAlign:'center',paddingTop:200}}>Wise Home Control technology</h1>

        <div style={{display:'flex',justifyContent:'center',paddingBottom:200,paddingTop:30,flexWrap:'wrap'}}>
            <div style={{maxWidth:450,width:'100%',backgroundColor:'#fff',display:'flex',justifyContent:'center'}}>
                <img width={'100%'} height={245} src={Schneider} style={{paddingTop:'25%'}} />

            </div>
            <div style={{maxWidth:450}}>
                <div style={{padding:30,paddingRight:10,fontSize:20,backgroundColor:'#505050',marginLeft:0,border:'1px solid #fff'}}>
                    <p style={{fontSize:16}}>
                        <span style={{fontSize:20}}><b>Dreamwood Tiny Homes</b> is a proud supplier of the <b>Wiser</b> Home Control system.<br/></span>
                        <br />

                        Smart homes are about technologies.<br/> <b>Wiser</b> Homes evolve around people. It adds a new dimension–being Stylish, Living Well, and Eco-friendly.<br/>
                        <b>Wiser</b> Home Control interlinks the electrical, multi-media, and telecommunication worlds with a single and user-friendly solution–enabling you to monitor, control, and access your home beyond physical boundaries at any time, <br />
                        anywhere, on your smartphone, computer, door entry system, web tablet… with the same intuitive graphic interface.
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>;