import React from 'react';
import person from '../images/person.jpeg';
import mail from '../images/mail.png';
import tahi from '../images/newRoof.png';
import rua from '../images/reroof.jpeg';
import toru from '../images/roofmaintenance.png';
import dreamwoodMain from '../images/dwmain2.png';
import home_icon from '../images/realtor-icon-4-dark.png';
import logo from '../images/DW_logos_reversed.png';
import firebase from "firebase/compat";
import {NavBar} from "./HeroPage";




export class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            contacts:null,
            authed:false,
            password:'',
        }
    }
    toggleActivePage(page){
        return this.setState({activePage:page})
    }
    componentDidMount() {
            const response=firebase.firestore().collection('contacts');
            response.get().then((data)=>{
                this.setState({contacts:data})
            });
    }
    authUser(e){
        this.setState({password:e.target.value})
    }
    checkAuth(password){
        if(password==='Dream2022'){
            this.setState({authed:true})
        }
        else{
            this.setState({password:''})
        }
    }

    render(){
        return <div>
            <NavBar toggleActivePage={this.toggleActivePage.bind(this)}/>
            <div className="mainImageBackground" style={{paddingLeft:50,paddingTop:40,paddingBottom:40}}>
                {this.state.authed ?
                    this.state.contacts && this.state.contacts.docs.map((item) => {
                        return (<h2 style={{color: '#fff'}}>{item.data().email} - {item.data().name}</h2>);
                    }) : <div>
                        <input value={this.state.password} onChange={(e)=>{this.authUser(e)}} type="text" style={{margin:5,padding:8,backgroundColor:'#505050',border:'1px solid #fff',color:'#fff',minWidth:210,marginRight:20}}
                               placeholder={'Enter password to continue'}/>
                               <br />
                       <div onClick={()=>{this.checkAuth(this.state.password)}} className="altButton" style={{width:250,marginTop:20,textAlign:'center',color:'#fff'}}>Continue</div>

                    </div>
                }
            </div>
        </div>

    }
}