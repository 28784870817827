import React from "react";
import firebase from "firebase/compat";
import { NavBar } from "./HeroPage";
import anvilMini from "../images/update/anvilMini.png";
import awanaMini from "../images/update/awanaMini.png";
import blindbayMini from "../images/update/blindbayMini.png";
import clarisMini from "../images/update/clarisMini.png";
import fitzroyMini from "../images/update/fitzroyMini.png";
import medlandsMini from "../images/update/medlandsMini.png";
import schoonerbayMini from "../images/update/schoonerbayMini.png";
import tryphenaMini from "../images/update/tryphenaMini.png";

//todo-cache update product range with new plans again, same format with Plan photo as thumbnail and click through element.
export class ProductRange extends React.Component {
  constructor(props) {
    super(props);
  }

  renderPlansFromArray(planImage, planName, planSize) {
    return (
      <div style={{ position: "relative", margin: 20 }}>
        <div
          className="myDIV2"
          onClick={() => {
            window.location.href = "/" + planName.toLowerCase();
          }}
        >
          <img
            src={planImage}
            className=" hoverImageDarkest imageMargins"
            width="400"
            height={300}
          />
        </div>
        <div className="imagePlus portfolioImage2 hide">
          <div
            onClick={() => {
              firebase
                .analytics()
                .logEvent(`view_${planName.toLowerCase()}_range`);
              window.fbq("trackCustom", "View_Product", {
                product: planName.toLowerCase(),
              });

              window.location.href = "/" + planName.toLowerCase();
            }}
          >
            <h2 className="imageHoverFont">{planName}</h2>
            <h5 className="imageHoverFontSmall">{planSize}m<sup>2</sup> Mobile tiny home</h5>
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log("firebase:", firebase.apps.length);
    return (
      <div>
        <NavBar />
        <div className="mainImageBackground" style={{ paddingBottom: 100 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "40%", borderBottom: "1px solid #fff" }}>
              <h1
                style={{ textAlign: "center", paddingTop: 20, color: "#fff" }}
              >
                Our Range
              </h1>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "inline-flex",
                flexWrap: "wrap",
                alignContent: "flex-start",
                alignItems: "space-around",
                justifyContent: "space-around",
              }}
            >
              {this.renderPlansFromArray(blindbayMini, "BlindBay",'21')}
              <br />
              {this.renderPlansFromArray(awanaMini, "Awana",'24')}
              <br />
              {this.renderPlansFromArray(anvilMini, "Anvil",'30')}
              <br />
              {this.renderPlansFromArray(fitzroyMini, "Fitzroy",'30')}
              <br />
              {this.renderPlansFromArray(medlandsMini, "Medlands",'30')}
              <br />
              {this.renderPlansFromArray(schoonerbayMini, "SchoonerBay",'30')}
              <br />
              {this.renderPlansFromArray(clarisMini, "Claris",'38')}
              <br />
              {this.renderPlansFromArray(tryphenaMini, "Tryphena",'38')}
            </div>
          </div>
          <div style={{ paddingTop: 120, color: "#fff" }}>
            <h2 style={{ textAlign: "center" }}>Not what you had in mind?</h2>
            <p style={{ textAlign: "center" }}>
              We offer custom plans and fabrication, get in touch to see if we
              can make your dream happen.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() => {
                  firebase.analytics().logEvent("view_contact_range");

                  window.location.href = "/contact";
                }}
                className="altButton"
                style={{ width: 300, marginTop: 20, textAlign: "center" }}
              >
                Make an enquiry
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
